import React from 'react'
import Typography from '@material-ui/core/Typography'
import SEO from 'components/elements/SEO/SEO'
import Button from '@material-ui/core/Button'
import Link from 'components/foundations/Link/Link'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="404: Non trovato" />
      <div className="closer">
        <Typography variant="h3">Pagina non trovata</Typography>
        <Typography variant="body1">Hai provato a visitare una pagina che non esiste.</Typography>
      </div>
      <p />
      <div className="closer">
        <Link to={`/`}>
          <Button className="btn btn-primary">
            <Typography component="h2" variant="button">
              Clicca qui per tornare alla home
            </Typography>
          </Button>
        </Link>
      </div>
    </>
  )
}
